html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}
body {
    overflow-x: hidden;
    line-height: 1.6;
    font-family: "nexa-slab", serif;
    color: #ffffff;
    background-color: #91478f;
    font-weight: 200;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
html {
    color: #222;
    font-size: 1em;
    line-height: 1.4;
    height: 100%;
}

.ti-cursor {
    display: none !important;
}
body.suggest {
    background-color: #c16ebf;
}
body.suggest .vertical-center {
    width: 380px;
}
body.suggest h2 {
    line-height: 1.2;
}
body.suggest h2 span.suggestion {
    font-size: 1em;
    font-family: "nexa", sans-serif;
}
body.suggest h2 a {
    color: #ffffff;
    text-decoration: none;
}
body.suggest h2 a:hover {
    color: #e5a7e3;
}
h1 {
    font-size: 3em;
    line-height: 1;
    color: #ffffff;
    margin-bottom: .5em;

}
h1 span.phrase {
    font-family: "nexa", sans-serif;
    display: block;
    font-size: 0.5em;
    line-height: 1.3;
}
a {
    text-decoration: none;
}

.panel {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.inner {
    width: auto;
}
.vertical-center {
    text-align: center;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    max-width: 300px;
}
 .make-suggestion {
    padding: 20px;
    text-align: center;
 }
.make-suggestion a {
    color: #ffffff;
}
.make-suggestion a:hover {
    color: #e5a7e3;
}
::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}
::selection {
    background: #b3d4fc;
    text-shadow: none;
}
hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}
fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}
textarea {
    resize: vertical;
}

.field {
    margin-bottom: 20px;
    position: relative;
    &.error {
        input[type="text"],
        input[type="email"],
        input[type="website"],
        textarea {
            background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23B81111'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23B81111' stroke='none'/%3E%3C/svg%3E");
            background-position:right calc(.375em + .1875rem) center;
            background-repeat:no-repeat;
            background-size:calc(.75em + .375rem) calc(.75em + .375rem);

            background-color: #FCD7E2;
            color: #d31048;
            border: 2px solid #d31048;
        }

        input[type="text"]:focus,
        input[type="email"]:focus,
        input[type="website"]:focus,
        textarea:focus,
        textarea.error:focus {
            background: #fff;
            box-shadow: 0;
            border: 3px solid #91478f;
            outline: none;
            color: #999999;
        }
    }
}

.h-captcha {
    margin-bottom: 20px;
}

input[type="text"],
input[type="email"],
input[type="website"],
textarea {
    color: #999999;
    font-size: 1emp;
    border-radius: 15px;
    line-height: 22px;
    background-color: #fbfbfb;
    padding: 13px 13px 13px 48px;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    border: 3px solid transparent;
}

label{
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #91478f;

    textarea{
        top:0;
    }
}

.pristine-error{
    display: none;
}
.results {
    display: block;
    border-radius: 15px;
    text-align: center;

    li{
        list-style: none;
    }
}
.results.error {
    background-color: #FCD7E2;
    color: #222222;
    border: #d31048 solid 2px;
    margin-bottom: 20px;
    padding: 30px;
}
.results.success {
    background-color: #e5a7e3;
    color: #222222;
    border: #91478f solid 2px;
    margin-bottom: 20px;
    padding: 30px;
}

.description {
    position: relative;
    display: inline-block;
}

.button {
    width: 100%;
    border: #fbfbfb solid 2px;
    cursor: pointer;
    background-color: transparent;
    color: white;
    font-size: 1.3em;
    padding-top: 15px;
    padding-bottom: 15px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    margin-top: -4px;
    font-weight: 700;
    border-radius: 20px;
}
.button:hover {
    background-color: #91478f;
}

.fade-in {
    opacity: 0;
    animation: fadeIn 5s;
    animation-delay: 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

@media only screen and (min-width: 580px) {
    .vertical-center {
        max-width: 480px;
    }
    h1 {
        font-size: 2.6em;
    }
    h1 span.phrase {
        font-size: 0.7em;
    }
}
@media only screen and (min-width: 980px) {
    body.suggest h2 {
        font-size: 2em;
    }
    body.suggest .vertical-center {
        width: 600px;
    }
    h1 {
        font-size: 1.6em;
    }
    h1 span.phrase {
        display: inline;
        border-bottom: 1px solid #ffffff;
        padding-bottom: 2px;
        text-align: center;
        font-size: 1em;
    }
    h1 span.ellipsis {
        display: none;
    }
    .vertical-center {
        min-width: 680px;
        max-width: 100%;
    }
}
@media only screen and (min-width: 1140px) {
    h1 {
        font-size: 1.8em;
    }
    h2 {
        font-size: 1em;
    }
}
