html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  color: #fff;
  background-color: #91478f;
  flex-direction: column;
  min-height: 100vh;
  font-family: nexa-slab, serif;
  font-weight: 200;
  line-height: 1.6;
  display: flex;
  overflow-x: hidden;
}

html {
  color: #222;
  height: 100%;
  font-size: 1em;
  line-height: 1.4;
}

.ti-cursor {
  display: none !important;
}

body.suggest {
  background-color: #c16ebf;
}

body.suggest .vertical-center {
  width: 380px;
}

body.suggest h2 {
  line-height: 1.2;
}

body.suggest h2 span.suggestion {
  font-family: nexa, sans-serif;
  font-size: 1em;
}

body.suggest h2 a {
  color: #fff;
  text-decoration: none;
}

body.suggest h2 a:hover {
  color: #e5a7e3;
}

h1 {
  color: #fff;
  margin-bottom: .5em;
  font-size: 3em;
  line-height: 1;
}

h1 span.phrase {
  font-family: nexa, sans-serif;
  font-size: .5em;
  line-height: 1.3;
  display: block;
}

a {
  text-decoration: none;
}

.panel {
  text-align: center;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.inner {
  width: auto;
}

.vertical-center {
  text-align: center;
  max-width: 300px;
  transition: all .3s;
}

.make-suggestion {
  text-align: center;
  padding: 20px;
}

.make-suggestion a {
  color: #fff;
}

.make-suggestion a:hover {
  color: #e5a7e3;
}

::selection {
  text-shadow: none;
  background: #b3d4fc;
}

hr {
  border: 0;
  border-top: 1px solid #ccc;
  height: 1px;
  margin: 1em 0;
  padding: 0;
  display: block;
}

audio, canvas, iframe, img, svg, video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.field {
  margin-bottom: 20px;
  position: relative;
}

.field.error input[type="text"], .field.error input[type="email"], .field.error input[type="website"], .field.error textarea {
  color: #d31048;
  background-color: #fcd7e2;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23B81111'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23B81111' stroke='none'/%3E%3C/svg%3E");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border: 2px solid #d31048;
}

.field.error input[type="text"]:focus, .field.error input[type="email"]:focus, .field.error input[type="website"]:focus, .field.error textarea:focus, .field.error textarea.error:focus {
  box-shadow: 0;
  color: #999;
  background: #fff;
  border: 3px solid #91478f;
  outline: none;
}

.h-captcha {
  margin-bottom: 20px;
}

input[type="text"], input[type="email"], input[type="website"], textarea {
  color: #999;
  font-size: 1emp;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
  background-color: #fbfbfb;
  border: 3px solid #0000;
  border-radius: 15px;
  width: 100%;
  padding: 13px 13px 13px 48px;
  line-height: 22px;
}

label {
  color: #91478f;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

label textarea {
  top: 0;
}

.pristine-error {
  display: none;
}

.results {
  text-align: center;
  border-radius: 15px;
  display: block;
}

.results li {
  list-style: none;
}

.results.error {
  color: #222;
  background-color: #fcd7e2;
  border: 2px solid #d31048;
  margin-bottom: 20px;
  padding: 30px;
}

.results.success {
  color: #222;
  background-color: #e5a7e3;
  border: 2px solid #91478f;
  margin-bottom: 20px;
  padding: 30px;
}

.description {
  display: inline-block;
  position: relative;
}

.button {
  cursor: pointer;
  color: #fff;
  background-color: #0000;
  border: 2px solid #fbfbfb;
  border-radius: 20px;
  width: 100%;
  margin-top: -4px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 1.3em;
  font-weight: 700;
  transition: all .3s;
}

.button:hover {
  background-color: #91478f;
}

.fade-in {
  opacity: 0;
  animation: 5s 3s forwards fadeIn;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media only screen and (width >= 580px) {
  .vertical-center {
    max-width: 480px;
  }

  h1 {
    font-size: 2.6em;
  }

  h1 span.phrase {
    font-size: .7em;
  }
}

@media only screen and (width >= 980px) {
  body.suggest h2 {
    font-size: 2em;
  }

  body.suggest .vertical-center {
    width: 600px;
  }

  h1 {
    font-size: 1.6em;
  }

  h1 span.phrase {
    text-align: center;
    border-bottom: 1px solid #fff;
    padding-bottom: 2px;
    font-size: 1em;
    display: inline;
  }

  h1 span.ellipsis {
    display: none;
  }

  .vertical-center {
    min-width: 680px;
    max-width: 100%;
  }
}

@media only screen and (width >= 1140px) {
  h1 {
    font-size: 1.8em;
  }

  h2 {
    font-size: 1em;
  }
}
/*# sourceMappingURL=main.cf36f281.css.map */
